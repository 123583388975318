// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-portfolio-corvallis-js": () => import("./../../../src/pages/portfolio/corvallis.js" /* webpackChunkName: "component---src-pages-portfolio-corvallis-js" */),
  "component---src-pages-portfolio-hue-js": () => import("./../../../src/pages/portfolio/hue.js" /* webpackChunkName: "component---src-pages-portfolio-hue-js" */),
  "component---src-pages-portfolio-index-js": () => import("./../../../src/pages/portfolio/index.js" /* webpackChunkName: "component---src-pages-portfolio-index-js" */),
  "component---src-pages-portfolio-j-hooks-js": () => import("./../../../src/pages/portfolio/j-hooks.js" /* webpackChunkName: "component---src-pages-portfolio-j-hooks-js" */),
  "component---src-pages-portfolio-jarvis-js": () => import("./../../../src/pages/portfolio/jarvis.js" /* webpackChunkName: "component---src-pages-portfolio-jarvis-js" */),
  "component---src-pages-portfolio-measure-js": () => import("./../../../src/pages/portfolio/measure.js" /* webpackChunkName: "component---src-pages-portfolio-measure-js" */)
}

